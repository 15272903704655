import { Button, Divider, Form, Input, Modal, message } from "antd";
import axiosConfig from "config/axios";
import { useState } from "react";
import { CheckSquareOutlined } from "@ant-design/icons";
import requestErrorHandler from "util/requestErrorHandler";
import { useNavigate } from "@reach/router";

function ReconcileQty({
  transaction,
  transactionId,
}: {
  transaction: Transaction;
  transactionId: string;
}) {
  const [quantityDelivered, setQuantityDelivered] = useState<number>(0);
  const [showReconcileModal, setShowReconcileModal] = useState(false);
  const [isReconciled, setIsReconciled] = useState<boolean>(false);
  const [completingOrder, setCompletingOrder] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const reconcileQty = async () => {
    setCompletingOrder(true);
    try {
      await axiosConfig.post(`builder/record-qty`, {
        quantityDelivered,
        transactionId,
        builderId: transaction?.builderId,
      });
      message.success("Qty reconciled");
      setShowReconcileModal(false);
      // setIsReconciled(true);
      navigate(
        `/admin/transactions/invoice/${transaction.id}?qty=${quantityDelivered}`
      );
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setCompletingOrder(false);
    }
  };
  return (
    <div>
      <Button
        onClick={() => setShowReconcileModal(true)}
        icon={<CheckSquareOutlined />}
        type="link"
        size="small"
        // disabled={orderConfirmed}
      >
        RECONCILE QUANTITY
      </Button>

      <Modal
        // className={styles.modal}
        title="RECONCILE QUANTITY"
        onCancel={() => setShowReconcileModal(false)}
        open={showReconcileModal}
        footer={false}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            rules={[{ required: true }]}
            name="amount"
            label="Enter delivered Quantity"
            style={{ textTransform: "capitalize" }}
          >
            <Input
              // defaultValue={}
              id="amount"
              // value={+amount > 0 ? amount : transaction.totalPrice}
              onChange={(e) => setQuantityDelivered(+e.target.value)}
            />
          </Form.Item>
          <Divider />
          <Button
            size="large"
            type="primary"
            style={{ width: "100%" }}
            onClick={reconcileQty}
            loading={completingOrder}
          >
            GENERATE INVOICE
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default ReconcileQty;
